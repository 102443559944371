function DiscordSection(){
    return (
        <section id="discordsectmobile">
            <h2>Dołącz do naszej społeczności Discord!</h2>
                    <p>To <a href="https://discord.gg/TCmqujhvh5">tutaj</a> znajdziesz odpowiedzi na wszelkie pytania i wątpliwości. Nasz przyjazny personel pracuje
                        ciężko żeby zapewnić wam najlepsze wrażenia z korzystania z aplikacji.
                        <br></br>
                        <br></br>
                        Znalazłeś jakiś błąd? Na discordzie znajdziesz również strefę przeznaczoną do rozwiązywania
                        problemów i zgłaszania błędów ogólnodostepną dla innych użytkowników, to znaczy że możecie
                        sprawdzić czy wasz problem był już wcześniej rozwiązany!
                    </p>
        </section>
    );
}

export default DiscordSection;