function FaqSection() {
    return (
        <section id="faqsect">
            <div id="faq">
                <h2>Najczęściej zadawane pytania</h2>
            </div>

            <div id="faqelement">
                <h3>Co oferuje nowe Ocenowo?</h3>
                <p>Ocenowo to zbiór narzędzi, które pomagają w nauce. Oferujemy Auto-instalinga V2 z zupełnie nowym algorytmem, który eliminuje błędy, które napotkaliście we Wulkanku. W przyszłości dostępny będzie też Auto-Lingos, Anti-Testportal V2 oraz Podpowiedzi EduElo!</p>
            </div>

            <div id="faqelement">
                <h3>Kiedy będą dostępne wszystkie funkcje?</h3>
                <p>Wszystkie przewidziane funkcje naszej aplikacji są już dla Was dostępne! Masz jakieś propozycje? Podziel się nimi na naszym Discordzie!</p>
            </div>

            <div id="faqelement">
                <h3>Co stało się z Dzienniczkiem Ocenowo?</h3>
                <p>Od teraz Dzienniczek Ocenowo to Ocenowo, musieliśmy dokonać kilka zmian po naszej stronie aby dalej móc dostarczać Wam najlepszy dzienniczek!</p>
            </div>

            <div id="faqelement">
                <h3>Co z moja subskrypcją z Wulkanka?</h3>
                <p>Jeżeli zakupiłeś subskrypcję przed 31-wszym stycznia 2024 roku we Wulkanku (starym Dzienniczku Ocenowo), możesz się do nas zgłosić o przeniesienie Ocenowo+ do nowej aplikacji.</p>
            </div>

            <div id="faqelement">
                <h3>Co oferuje Auto-instaling V2?</h3>
                <p>Auto-instaling V2 z zupełnie nowym algorytmem eliminuje błędy, które napotkaliście we Wulkanku. Pomyłki w rozwiązywaniu zdarzają się bardzo rzadko, a jeżeli się zdarzą zostaniesz poproszony o ręczne wprowadzenie definicji słówka, przez co następnym razem pomyłka się nie powtórzy, o ile wpiszesz poprawną odpowiedź. </p>
            </div>
        </section>
    );
}

export default FaqSection;