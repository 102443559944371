function Footer() {
    return (
        <footer>
            <div id="downloads" className="footerdiv">
                <div className="badge">
                    <a href='https://play.google.com/store/apps/details?id=com.ocenowo.ocenowo' target="_blank" rel="noreferrer">
                    <div id="badgeImage">
                        <img alt='pobierz z Google Play' src='img/Ocenowo/Play_Store.png' />
                    </div> 
                    </a>
                </div>
                <div className="badge">
                    <a href='https://apps.apple.com/pl/app/ocenowo-narz%C4%99dzia-szkolne/id6478110214?platform=iphone' target="_blank" rel="noreferrer">
                    <div id="badgeImage">
                    <img alt='pobierz z App Store' src='img/Ocenowo/App_Store.png' />   
                    </div>        
                    </a>           
                </div>

            </div>
            <div id="credits" className="footerdiv">
                <p>©2024 Copyright - Ocenowo | <a href="https://ocenowo.com/privacy-policy-and-terms-of-use.html"
                    target="_blank" rel="noreferrer">Polityka prywatnosci</a> | App by <a
                        href="https://www.linkedin.com/in/martin-gogolowicz/" target="_blank" rel="noreferrer">Martin Gogołowicz</a> |
                    Website by Ranpenzo</p>
            </div>
        </footer>
    );
}

export default Footer;