function Hero(){
    const handleClickScroll = () => {
        const element = document.getElementById('featuresect');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };


    return (
        <section id="welcome">
            <div id="left">
                <div id="welcometext">
                    <p>Poznaj Ocenowo w nowej odsłonie.</p>
                </div>  
                <div id="herobadges">
                <div className="badge">
                    <a href='https://play.google.com/store/apps/details?id=com.ocenowo.ocenowo' target="_blank" rel="noreferrer">
                    <div id="badgeImage">
                        <img alt='pobierz z Google Play' src='img/Ocenowo/Play_Store.png' />
                    </div>
                    </a>
                </div>
                <div className="badge">
                    <a href='https://apps.apple.com/pl/app/ocenowo-narz%C4%99dzia-szkolne/id6478110214?platform=iphone' target="_blank" rel="noreferrer">
                    <div id="badgeImage">
                        <img alt='pobierz z App Store' src='img/Ocenowo/App_Store.png' />
                    </div>
                    </a>
                </div>
                </div>
                <div id="scrolldown" >
                    <p>Przewiń, żeby poznać nowości.</p>
                    <div id="scrollarrow" onClick={handleClickScroll}>
                    <i className="arrow down"></i>
                    </div>
                </div>
                </div>
            <div id="right">
                <div id="topr">
                    <img src="img/Ocenowo/mockup.png" alt=""></img>                    
                </div>                
            </div>
        </section>
    );
}

export default Hero;