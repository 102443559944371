function DiscordSection(){
    return (
        <section id="discordsect">
            <div className="dc-block">
                <div id="frame">
                    <iframe src="https://discord.com/widget?id=1188992321472184451&theme=dark" width="350" height="500"
                        allowtransparency="true" frameBorder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                        title="Discord"></iframe>
                </div>
            </div>
            <div className="dc-block">
                <h2>Dołącz do naszej społeczności Discord!</h2>
                    <p>To tutaj znajdziesz odpowiedzi na wszelkie pytania i wątpliwości. Nasz przyjazny personel pracuje
                        ciężko żeby zapewnić wam najlepsze wrażenia z korzystania z aplikacji.</p>
                        <br></br>
                    <p>Znalazłeś jakiś błąd? Na discordzie znajdziesz również strefę przeznaczoną do rozwiązywania
                        problemów i zgłaszania błędów ogólnodostepną dla innych użytkowników, to znaczy że możecie
                        sprawdzić czy wasz problem był już wcześniej rozwiązany!
                    </p>
            </div>
        </section>
    );
}

export default DiscordSection;