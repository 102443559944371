import React, { useEffect } from 'react';

import './App.css';
import Nav from '../../components/Ocenowo/Nav';
import Hero from '../../components/Ocenowo/Hero';
import FeatureSection3 from '../../components/Ocenowo/FeatureSection';
import FaqSection from '../../components/Ocenowo/FaqSection';
import DiscordSection from '../../components/Ocenowo/DiscordSection';
import DiscordSectionMobile from '../../components/Ocenowo/DiscordSectionMobile';
import Footer from '../../components/Ocenowo/Footer';
// import ImageCarousel from '../../components/Ocenowo/carousel';

export default function Ocenowo(){
    useEffect(() => {
        document.title = 'Ocenowo';
        const metaDescription = document.querySelector('meta[name="description"]');
        const metaIcon = document.querySelector('link[rel="icon"]');
        metaDescription.setAttribute('content', "Ocenowo to zbiór narzędzi, które pomagają w nauce i oszczędzają czas. ");
        metaIcon.setAttribute('href', "img/Ocenowo/ocenowo_logo.png")
    }, []);

    return (
        <div className="AppO">
            <Nav/>
            <main>
                <Hero></Hero>                
                <FeatureSection3></FeatureSection3>
                <FaqSection></FaqSection>
                <DiscordSection></DiscordSection>
                <DiscordSectionMobile></DiscordSectionMobile>
                <Footer></Footer>
            </main>
        </div>
    )
}