import React, { useEffect } from 'react';

import '../Ocenowo/App.css';


export default function Troubleshooting() {
  useEffect(() => {
    document.title = 'Poradnik naprawy błędów Ocenowo';
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaIcon = document.querySelector('link[rel="icon"]');
    metaDescription.setAttribute('content', "Na tej stronie znajdziesz wszelkie problemy związane z Ocenowo, które możesz rozwiązać na własną rękę.");
    metaIcon.setAttribute('href', "img/Dzienniczek/ikona_logo.png")
  }, []);

  return (
    <div className="AppO">
      <div id="troubleshooting">
        <p>Najczęściej spotykane błędy</p>
       </div>

       <div id="faqelement" className='trouble'>
                <h3>Sesja Auto Instaling/Auto Lingos/Anti-Testportal nie uruchamia się</h3>
                
                <p>Prawdopodobnie Twój telefon blokuje dostęp do odbiornika reklam Google. Aby naprawić ten problem musisz wyłączyć blokady reklam w telefonie.
                    
                    

                    
                    
                    <br></br><br></br>
                    Jakie blokady reklam? Najczęściej jest to prywatny DNS, ale problemem może też być dedykowana aplikacja do blokowania reklam taka jak:
                    <ul id='adblocklist'>
                        <li>AdAway</li>
                        <li>AdGuard</li>
                        <li>Wszelkie aplikacje z Adblock w nazwie</li>
                    </ul>

                    <b>Instrukcje rozwiązywania problemu dla IOS</b>
                    <br></br>
                    <a href='https://adguard-dns.io/kb/private-dns/solving-problems/remove-dns-profile/' target='_blank' rel='noreferrer'>
                    https://adguard-dns.io/kb/private-dns/solving-problems/remove-dns-profile/
                    </a>
                    <br></br>
                    <br></br>
                    <b>Instrukcje dla Androida poniżej:</b>
                    <br></br><br></br>
                </p>

                <h3>Wykonuj poniższe kroki, aby rozwiązać problem. (Android)</h3>

                <h4>Krok 1.</h4>
                <p>Otwórz ustawienia swojego telefonu</p>
                <img src='../../img/Dns/samsung.png' className='settingIcon' alt='Samsung settings logo'></img>
                <img src='../../img/Dns/huawei.png' className='settingIcon' alt='Huawei settings logo'></img>
                <img src='../../img/Dns/stock.png' className='settingIcon' alt='Android settings logo'></img>
                <br></br>

                <h4>Krok 2.</h4>
                <p>Wyszukaj za pomocą "lupy" tę frazę: "Prywatny DNS" i wejdź w tę opcję.</p>
                <img src='../../img/Dns/dns.jpg' id='dns' alt=''></img>
                <br></br>

                <h4>Krok 3.</h4>
                <p>Kliknij "Prywatny DNS", a następnie wybierz "Wyłączony" spośród innych dostępnych opcji.</p>
                <img src='../../img/Dns/dns2.jpg' id='dns' alt=''></img>
                <br></br>

                <h4>Problem nie ustąpił?</h4>
                <p>Odinstaluj lub dezaktywuj aplikacje do blokowania reklam takie jak AdAway, AdGuard, Adblock.
                    <br></br>
                    Jeżeli problem nadal występuje zgłoś się do nas <a href="https://discord.gg/TCmqujhvh5">na naszym Discordzie</a>.
                </p>
                <br></br>
        </div>
    </div>
  );
}