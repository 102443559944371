function FeatureSection(){
    return(
        
        <section id="featuresect">
            <div id="whitespace"></div>
            <div className="feature">
                <h2>Auto-instaling V2</h2>
                <p>Poznaj Autoinstaling w nowej odsłonie! Funkcja została zaprojektowana od zera aby zapewnić Wam szybsze rozwiązywanie sesji, minimalizując ryzyko błędów w odpowiedziach. Zadbaliśmy również o wszelkie błędy i propozycje funkcji które zgłaszali nasi cudowni użytkownicy.</p>
            </div>
            <div className="feature">
                <h2>Auto-Lingos</h2>
                <p>Masz dość zadań na platformie Lingos? Nasza aplikacja odrobi je za Ciebie, abyś mógł skoncentrować się na swoich zainteresowaniach i oszczędzić trochę cennego czasu.</p>
            </div>
            <div className="feature">
                <h2>Anti-Testportal</h2>
                <p>Masz testy na testportalu? To świetna wiadomość! Mamy dla Ciebie Anti-Testportal. Dzięki nam możesz swobodnie wychodzić z testów oraz ułatwiać sobie ich rozwiązywanie za pomocą budowania zapytań dla ChatuGPT! </p>
            </div>    
            <div className="feature">
                <h2>EduElo</h2>
                <p>Czy może być coś lepszego niż podpowiedzi do zadań? Teraz dzięki Ocenowo ułatwisz sobie rozwiązywanie zadań również na platformie EduElo!</p>
            </div>                            
        </section>
    );
}

export default FeatureSection;