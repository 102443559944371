function Hero(){
    return(
        <div className="hero">
            <h1>Twoje nowe doświadczenie z <br/>dziennikiem szkolnym</h1>
            <img src="img/Dzienniczek/blur2.png" className="topElement" alt="Blur element"/>
            <img src="img/Dzienniczek/HeroMockup.png" alt="Telefon z dziennikiem Ocenowo."/>
            <img src="img/Dzienniczek/blur1.png" className="bottomElement" alt="Blur element"/>
        </div>
    );
}

export default Hero;