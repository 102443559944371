import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Dzienniczek from './pages/Dzienniczek/dzienniczek';
import Ocenowo from './pages/Ocenowo/ocenowo';
import Troubleshooting from './pages/Dns/dns';
import Konkurs from './pages/Konkurs/konkurs';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Ocenowo/>}></Route>
        <Route path="/dzienniczek" element={<Dzienniczek/>}></Route>
        <Route path='/rozwiazywanie-problemow' element={<Troubleshooting/>}></Route>
        <Route path='/konkurs' element={<Konkurs/>}></Route>
      </Routes>
    </Router>
  );
};
export default App;
