import React, { useEffect } from 'react';

import '../Ocenowo/App.css';


export default function Konkurs() {
  useEffect(() => {
    document.title = 'Konkurs 3 lata konta premium';
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaIcon = document.querySelector('link[rel="icon"]');
    metaDescription.setAttribute('content', "Na tej stronie dowiesz się co zrobić aby wygrać 3 lata konta premium.");
    metaIcon.setAttribute('href', "img/Dzienniczek/ikona_logo.png")
  }, []);

  return (
    <div className="AppO">
      <div id="konkurs">
        <h1>Konkurs na 3 lata konta premium</h1>
        

        <div id='contestCard'>
            <h2>Co jest przedmiotem konkursu?</h2>
            <p>
              Do wygrania w konkursie oferujemy dwie możliwości. Jedna do wyboru:              
            </p>
            <ul>
              <li>3 lata konta premium</li>
              <li>3x rok konta premium</li>
            </ul>
        </div>   

        <div id='contestCard'>
            <h2>Co trzeba zrobić aby wygrać?</h2>
            <p>
              Zasady są proste, a nagrody przyjemne. Musicie uzyskać jak największy rozgłos na temat naszej aplikacji na TikToku. <b>Wygra ten kto zdobędzie jak najwięcej polubień na <u>jednym</u> TikToku. Możecie tworzyć nieskończoną ilość tiktoków, ale liczy się ten z największą ilością polubień</b>               
            </p>   
            
            <h2>Krok 1</h2>            
            <p>
              Tworzycie TikToka, który spodoba się jego użytkownikom na tyle, aby ich przyciągnąć do aplikacji. Możecie stworzyć zarówno slajdy jak i zwykły filmik. 
            </p>
            <img src='../../img/Konkurs/screen.png' className='settingIcon' alt=''></img>
            <p>Co musicie zrobić aby się kwalifikować do wygrania nagrody?</p>
            <ul>
              <li>Dodać <b>#ocenowo</b> do Waszego TikToka</li>
            </ul>

            <p>Co was dyskwalifikuje?</p>
            <ul>
              <li>Kopie Tiktoków z naszego profilu.</li>
            </ul>

            <h2>Krok 2</h2>            
            <p>
              Obserwujecie jak Wasz TikTok się wybija do 30 maja 2024 roku.  
            </p>   

            <h2>Krok 3</h2>            
            <p>
              Chwalicie się wśród znajomych lub na mediach społecznościowych swoją wygraną subskrypcją 🥰. 
            </p>

            <h2>Zwycięzca zostanie wyłoniony na naszym <a href='https://discord.gg/dbjx5hzyn6' target='_blank' rel="noreferrer">Discordzie</a>.</h2>   
            <p>Dla wyjątkowych zawodników może czekać bonus, taki jak współpraca z Ocenowo na posadzie Content Creatora</p>
        </div> 
       </div>
    </div>
  );
}